import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./Home.js";
import logo from "./logo.png"
import React,{useState,useEffect} from "react";
import {getUser,getToken,setUserSession,resetUserSession} from "./service/AuthService";
import axios from 'axios';

const verifyTokenAPIURL = "https://fin8n2q4gf.execute-api.us-east-1.amazonaws.com/prod/verify"

function App() {

  const [isAuthenticating,setAuthenticating]=useState(true);

    useEffect(() => 

       {
        const token = getToken();
        if(token==='undefined' || token === undefined || token===null || !token){
          return;
        }

        const requestConfig = {
          headers: {
            'x-api-key':'5wydiRcnqx3qNFtEjPHQx5gRdqGEF4so9Lh3Zwwv'
          }
        }
        const requestBody = {
          window_id: getUser(),
          token: token
        }

        axios.post(verifyTokenAPIURL,requestBody,requestConfig).then(response => {
          setUserSession(response.data.user,response.data.token);
          setAuthenticating(false);
        }).catch(()=>{
          resetUserSession();
          setAuthenticating(false);
        })


       },[]);

    const token = getToken();
    if (isAuthenticating && token){
      return <div className="content">Authenticating...</div>
    }

    // const img = document.querySelector("img"); 
    return (


      <div className="App">

        <BrowserRouter>

        <div className="header">

          <h2>Window Study Login Portal</h2>

        </div>


        <img src={logo} alt="Window Study" height = {200} />


        <div className="content">

          <Switch>
            <Route exact path="/" component={Home}/>
            {/*<Route exact path="/"/>*/}
          </Switch>

        </div>

        </BrowserRouter>

      </div>
    );
  
}

export default App
