import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

const registerUrl = 'https://fin8n2q4gf.execute-api.us-east-1.amazonaws.com/prod/register';
// const redcapUrl = 'https://www.ctsiredcap.pitt.edu/redcap/api/';
const redcapUrl = 'https://redcap-std.hs.pitt.edu/redcap/api/';

const token = 'AF4BF98891D45193DBB1195ADBDA77C2';

function strip(html)
{
   var tmp = document.implementation.createHTMLDocument("New").body;
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const ButtonMailto = ({ mailto, label }) => {
			    return (
			        <Link
			            to='#'
			            onClick={(e) => {
			                window.location.href = mailto;
			                e.preventDefault();
			            }}
			        >
			            {label}
			        </Link>
			    );
			};

const Home = () => {

		const [window_id,setwindow_id] = useState('');
		const [dob,setDob] = useState('');
		// const [consent_link,setConsentlink] = useState('');
		const [message, setMessage] = useState(null);

		const submitHandler = (event) => {
			event.preventDefault();
			// if (window_id.trim()==='' || dob.trim()===''){
			// 	setMessage('All fields are required.');
			// 	return;
			// }

			if (window_id==='' || dob===''){
				setMessage('All fields are required.');
				return;
			}

			const requestConfig = {
				headers: {
					'x-api-key':'5wydiRcnqx3qNFtEjPHQx5gRdqGEF4so9Lh3Zwwv'
				}
			}

			// const requestBody = {
			// 	window_id: window_id
			// }

			const requestBody2 = {
				window_id: window_id,
				dob: dob
			}

			const data2 = new FormData();
			data2.append('token',token);
			data2.append('content', 'surveyLink');
			data2.append('format','json');
			data2.append('instrument','consent');
			data2.append('event','');
			data2.append('record',window_id); // put in a record here directly to test!  - comment when done testing
			// data2.append('record',JSON.stringify('999999')); // put in a record here directly to test!  - comment when done testing
			// data2.append('record',JSON.stringify(requestBody)); // what the code should be - uncomment when done testing
			data2.append('returnFormat','json')

			const params = {
    			method: 'POST',
    			body: data2,
    			encode: 'form',
    			format: 'json'
  			}


  			// fetches the survey link from REDCap and then appends the record ID and date of birth to the URL as query parameters. You can then use the linkedsurveyLink for your purposes.

			axios.post(registerUrl,requestBody2,requestConfig).then(response=> {

				fetch(redcapUrl, params).then(async data1 => {
					const surveyLink = await data1.text()
					const linkedsurveyLink = `${surveyLink}&id=${window_id}&dob=${dob}`;

    				// console.log('Please click to continue to survey link:',surveyLink)
    				console.log('Please click to continue to survey link:',linkedsurveyLink)
					// setMessage(<Link to={{ pathname: surveyLink }}>Please click to continue to survey link</Link>)
					// setMessage(<Link to={{ pathname: surveyLink }} target="_blank">Please click to continue to survey link (new tab)</Link>)
					setMessage(<Link to={{ pathname: linkedsurveyLink }} target="_blank">Please click to continue to survey link (new tab)</Link>)



  				}).catch(error => setMessage('No survey link found. Please contact us.'))

			}).catch(error => {

				if (error.response.status === 401 || error.response.status === 403){
					setMessage(error.response.data.message);
				} else {
					setMessage('Sorry... the backend server is down. Please try again later.')
				}
			})

			

		}

		return(
			<div>

				Please login below to complete the consent form. <br/>

				<br/>

				After completing the consent form, you will receive an email with a link to the survey. 
				If you have any questions or would like to complete this form over the phone with study staff, 
				please call or text us at 412-204-7458 or email us at <ButtonMailto label="WindowStudy@upmc.edu" mailto="mailto:WindowStudy@upmc.edu" />. <br/> 
				
				<br/>

				Thanks!

				<br/>

				<br/>

				<form onSubmit={submitHandler}>

				Study ID: <input type="text" value={window_id} onChange={event => setwindow_id(event.target.value)} /> <br/>
				<br/>
				Date of Birth (mm-dd-yyyy): <input type="text" value={dob} onChange={event => setDob(event.target.value)} /> <br/>
				
				<br/>

				<input type="submit" value="Submit" />
				</form>
				{message && <p className="message">{
					message

				} </p>}

			</div>
		)



}

export default Home;