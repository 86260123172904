module.exports = {
	getUser: function() {
		const window_id = sessionStorage.getItem('window_id');
		if(window_id === 'undefined' || !window_id){
			return null;
		}else{
			return JSON.parse(window_id);
		}
	},

	getToken: function(){
		return sessionStorage.getItem('token');
	},

	setUserSession: function(window_id,token){
		sessionStorage.setItem('window_id',JSON.stringify(window_id));
		sessionStorage.setItem('token',token);
	}

}


